import React, { createContext, useState, useEffect } from 'react';
import { useShopifyCookies } from '@shopify/hydrogen-react';

import Geoloc from '~/helpers/geoloc'
import QlReq from '~/helpers/ql_req'
import * as Queries from '~/helpers/ql_queries'
import { checkMobile } from '~/helpers/helpers'

const ThemeContext = createContext();

const checkCustomer = () => {
  if ((typeof localStorage === "undefined") || !localStorage.getItem('cu')) {
    return false
  }

  const cust_obj = JSON.parse(localStorage.getItem('cu'))
  const expires = Date.parse(cust_obj.expires)
  cust_obj['check_expire'] = true
  if ( Date.now() > expires ) return false
  return cust_obj
}

const checkCart = () => {
  if ( (typeof localStorage === "undefined") || !localStorage.getItem('cart')) {
    return null
  }
  const loc_cart = JSON.parse(localStorage.getItem('cart'))
  loc_cart['refresh'] = 'loc'
  return loc_cart
}

const checkRecs = () => {
  if ( (typeof localStorage === "undefined") || !localStorage.getItem('cartRecs')) {
    return []
  }
  const loc_recs = JSON.parse(localStorage.getItem('cartRecs'))
  const time_diff = Date.now() - loc_recs.time
  if (time_diff > 10800000) {
    localStorage.removeItem('cartRecs')
    return []
  }
  return loc_recs.recs
}

const checkGender = () => {
  if ((typeof localStorage === "undefined") || (typeof window === "undefined")) return 'men'
  
  const params = new URLSearchParams(window.location.search)
  if (params.get('g')) return params.get('g')

  const is_w = window.location.pathname.includes('/collections/women')
  const set_gend = localStorage.getItem('homeg') ? localStorage.getItem('homeg') : is_w ? 'women' : 'men'

  return set_gend
}

const checkSavedLoc = () => {
  if (typeof localStorage === "undefined") return null
  
  const savd = localStorage.getItem('locate')
  const locarr = savd ? savd.split('|') : []
  let savd_time = locarr[0]
  let savd_loc = locarr[1] ? JSON.parse(locarr[1]) : false

  if (savd_loc && savd_time > (Date.now() - 600000)) return savd_loc
  return null
}


const ThemeProvider = ({ children }) => {
  const { getGeo } = Geoloc()
  useShopifyCookies({hasUserConsent: true})

  const browser_gender = checkGender()
  const mobile_info = checkMobile()

  const [isSHM, setIsSHM] = useState(null);
  const [isMobile, setIsMobile] = useState(mobile_info.mobile);
  const [isTablet, setIsTablet] = useState(mobile_info.tablet);
  const [isTouches, setIsTouches] = useState(mobile_info.touches);
  const [locate, setLocate] = useState(checkSavedLoc());
  const [freeze, setFreeze] = useState(false);
  const [gender, setGender] = useState( browser_gender );
  const [bread, setBread] = useState(false);
  const [variant, setVariant] = useState(false);
  const [cart, setCart] = useState(checkCart());
  const [cartClose, setCartClose] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [cartChanging, setCartChanging] = useState(false)
  const [cartError, setCartError] = useState(false)
  const [cartRecs, setCartRecs] = useState(checkRecs());
  const [mobileMenu, setMobileMenu] = useState(false);
  const [collPanel, setCollPanel] = useState(false);
  const [collGender, setCollGender] = useState(null);
  const [storesOpen, setStoresOpen] = useState(false);
  const [storesProduct, setStoresProduct] = useState(false);
  const [blockHeader, setBlockHeader] = useState(false);
  const [pdpInv, setPdpInv] = useState(false);
  const [search, setSearch] = useState(false);
  const [showSizing, setShowSizing] = useState(false);
  const [showGiftCard, setShowGiftCard] = useState(false);
  const [giftInfo, setGiftInfo] = useState(false);
  const [quick, setQuick] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [customer, setCustomer] = useState(checkCustomer());
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [pageProps, setPageProps] = useState({pageType: null});

  const checkSHM = async () => {
    const query = Queries.q_metafield()
    const vars = {
      namespace: 'pima',
      key: 'shopify_holiday_mode'
    }
    const req = await QlReq.post(query, vars)
    const metacheck = req.shop.metafield.value === 'true'
    setIsSHM(metacheck)
  }

  const initLoc = async () => {
    const savd = localStorage.getItem('locate')
    const locarr = savd ? savd.split('|') : []
    let savd_loc = locarr[1] ? JSON.parse(locarr[1]) : {}

    const geo = await getGeo(true, savd_loc)
    setLocate(geo)
  }

  const checkCartValid = async () => {
    const loc_cart = JSON.parse(localStorage.getItem('cart'))

    const vars = {
      id: loc_cart.id
    }
    const query = Queries.q_cart_valid()
    const req = await QlReq.post(query, vars)

    const is_valid = req.cart !== null

    if (!is_valid) {
      localStorage.removeItem('cart')
      setCart(false)
      return
    }

    loc_cart['refresh'] = true
    localStorage.setItem('cart', JSON.stringify(loc_cart))
    setCart(loc_cart)
  }

  const set_refer = () => {
    if (typeof document === 'undefined') return
    const is_local = window.location.hostname.includes('localhost')
    let expires = 1
    let date = new Date()
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toUTCString()}`
    const params = new URLSearchParams(window.location.search)
    const obj = {
      p: window.location.pathname,
      r: document.referrer.includes('buckmason.com') ? '' : document.referrer,
      a: params.get('utm_campaign') || false,
      o: params.get('utm_content') || false,
      m: params.get('utm_medium') || false,
      s: params.get('utm_source') || false,
      t: params.get('utm_term') || false,
      d: Date.now
    }
    document.cookie = `re=${JSON.stringify(obj)}${expires};path=/;${is_local ? '' : 'domain=buckmason.com'}`
  }

  const check_test_params = () => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('single')) {
      const ttype = params.get('single')
      if (ttype === 'false') {
        sessionStorage.removeItem('single')
      } else {
        sessionStorage.setItem('single',ttype)
      }
    }
    if (params.get('ship')) {
      const ttype = params.get('ship')
      if (ttype === 'false') {
        sessionStorage.removeItem('ship')
      } else {
        sessionStorage.setItem('ship','true')
      }
    }
    // if (params.get('testintl')) {
    //   const ttype = params.get('testintl')
    //   if (ttype === 'false') {
    //     localStorage.removeItem('locate')
    //     initLoc()
    //   } else {
    //     setLocate({
    //       ...locate,
    //       country: 'CA',
    //       zip: 'X0G',
    //       shipIntl: true
    //     })
    //   }
    // }
    // if (params.get('pdptest')) {
    //   const ttype = params.get('pdptest')
    //   if (ttype === 'false') {
    //     sessionStorage.removeItem('pdptest')
    //   } else {
    //     sessionStorage.setItem('pdptest','true')
    //   }
    // }
  }

  useEffect(() => {
    check_test_params()
    
    const is_stag = process.env.GATSBY_CURR_ENV === 'development'

    if ( localStorage.getItem('cart') && cart.refresh === 'loc' ) {
      // setCart(JSON.parse(localStorage.getItem('cart')))
      checkCartValid()
    }

    if (isSHM === null) checkSHM()
    if (!locate) initLoc()

    if ((typeof window !== "undefined") && window.location.pathname === '/account/' && !localStorage.getItem('cu')) {
      window.location = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'
    }
    
    set_refer()

    if (window.location.search.includes('utest=true')) {
      localStorage.setItem('unitary','true')
    }
    if (window.location.search.includes('utest=false')) {
      localStorage.removeItem('unitary')
    }

    document.querySelector('html').setAttribute('hg', browser_gender)
    document.querySelector('html').setAttribute('e', is_stag ? 'd' : 'p')
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!locate) return
    localStorage.setItem('locate', `${Date.now()}|${JSON.stringify(locate)}`)
  }, [locate])

  useEffect(() => {
    document.querySelector('html').style.overflow = freeze ? 'hidden' : ''
  }, [freeze])

  const resize_check = () => {
    const mobile_info = checkMobile()
    setIsMobile(mobile_info.mobile);
    setIsTablet(mobile_info.tablet);
    setIsTouches(mobile_info.touches);
    const html_el = document.querySelector('html')
    html_el.setAttribute('is-m', mobile_info.mobile ? 'true' : 'false')
    mobile_info.touches ? html_el.setAttribute('is-t','true') : html_el.removeAttribute('is-t')
  }

  const key_check = (e) => {
    if (e.key === 'Tab') document.querySelector('html').classList.add('using-keyboard')
  }

  const key_clear = (e) => {
    document.querySelector('html').classList.remove('using-keyboard')
  }

  useEffect(() => {
    window.addEventListener('resize', resize_check, { passive: true });
    window.addEventListener('keydown', key_check);

    if (document.querySelector('html').classList.contains('using-keyboard') ) {
      window.addEventListener('click', key_clear)
    }

    return () => {
      window.removeEventListener('resize', resize_check);
      window.removeEventListener('keydown', key_check);
      // window.removeEventListener('click', key_clear);
    };
  });

  useEffect(() => {
    document.querySelector('html').setAttribute('hg', gender)
    localStorage.setItem('homeg', gender)
  }, [gender]);

  useEffect(() => {
    if (!cartOpen) setCartClose(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartOpen]);



  return (
    <ThemeContext.Provider
      value={{
        analytics, setAnalytics,
        isMobile, isTablet, isTouches,
        locate, setLocate, 
        gender, setGender,
        bread, setBread,
        freeze, setFreeze,
        variant, setVariant,
        cart, setCart,
        cartClose, setCartClose,
        cartOpen, setCartOpen,
        cartChanging, setCartChanging,
        cartError, setCartError,
        cartRecs, setCartRecs,
        mobileMenu, setMobileMenu,
        collPanel, setCollPanel,
        collGender, setCollGender,
        storesOpen, setStoresOpen,
        storesProduct, setStoresProduct,
        blockHeader, setBlockHeader,
        pdpInv, setPdpInv,
        quick, setQuick,
        search, setSearch,
        showSizing, setShowSizing,
        showGiftCard, setShowGiftCard,
        giftInfo, setGiftInfo,
        customer, setCustomer,
        orders, setOrders,
        order, setOrder,
        pageProps, setPageProps
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };


// const defaultState = {
//   dark: false,
//   toggleDark: () => {},
// }

// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

// const state = {
//   dark: false,
//   panel: 'panel-men',
//   sub: false
// }

// const toggleDark = () => {
//   let dark = !this.state.dark
//   localStorage.setItem("dark", JSON.stringify(dark))
//   this.setState({ dark })
// }

// const togglePanel = (e) => {
//   const panel = e.target.closest('[site-menu]').getAttribute('site-menu')
//   this.setState({ panel })
// }

// const toggleSub = (e) => {
//   const name = e.target.closest('[data-sub]').getAttribute('data-sub')
//   const sub = this.state.sub === name ? false : name
//   this.setState({ sub })
// }
// state,
// toggleDark: toggleDark,
// togglePanel: togglePanel,
// toggleSub: toggleSub,

// useEffect(() => {
//   const lsDark = JSON.parse(localStorage.getItem("dark"))
//   if (lsDark) {
//     this.setState({ dark: lsDark })
//   } else if (supportsDarkMode()) {
//     this.setState({ dark: true })
//   }
// }, []);
